import { Route, Routes } from "react-router-dom";

import "./App.css";

import Join from "./components/join";
import EmptyInviteRedirect from "./components/EmptyInviteRedirect";
import Download from "./components/join/download";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<EmptyInviteRedirect />} />
        <Route path="/download" element={<Download />} />
        <Route path="/:inviteId" element={<Join />} />
      </Routes>
    </div>
  );
}

export default App;
