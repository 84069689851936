import React, {useState} from "react";

import "react-phone-number-input/style.css";

import JoiOrig from "joi";
import PhoneInput from "react-phone-number-input";
import {
    ANDROID_APP_DOWNLOAD_URL,
    IOS_APP_DOWNLOAD_URL,
    KSC_API_URI,
} from "../../constants";
import {useNavigate} from "react-router-dom";

const Joi = JoiOrig.extend(require("joi-phone-number"));

const phoneInputSchema = Joi.object({
    phone: Joi.string()
        .trim()
        .phoneNumber({strict: true, format: "international"})
        .required()
        .messages({
            "phoneNumber.invalid": "Input did not seem to be a phone number",
        }),
    friendship_invite_id: Joi.string().trim().required(),
});

// returns 'ios' | 'android' | 'other'
const getDeviceType = () => {
    if (
        navigator.userAgent.match(/iphone/i) ||
        navigator.userAgent.match(/ipad/i) ||
        navigator.userAgent.match(/ipod/i)
    ) {
        return "ios";
    }
    if (navigator.userAgent.match(/android/i)) {
        return "android";
    }
    return "other";
};

const redirectToAppDownload = (navigate) => {
    const deviceType = getDeviceType();
    switch (deviceType) {
        case "ios":
            window.location.href = IOS_APP_DOWNLOAD_URL;
            return;
        case "android":
            window.location.href = ANDROID_APP_DOWNLOAD_URL;
            return;
        default:
            navigate("/download");
            return;
    }
};

const PhoneForm = ({inviteId, isLoading, setIsLoading}) => {
    const [phone, setPhone] = useState("");
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    const onRequestStart = () => {
        setErrors({});
        setIsLoading(true);
    };
    const onRequestEnd = () => {
        setPhone("");
        setIsLoading(false);
    };

    const onPhoneChange = (value) => {
        if (errors.phone) {
            setErrors({...errors, phone: undefined});
        }
        setPhone(value);
    };

    const submitPhone = () => {
        onRequestStart();
        const {value, error} = phoneInputSchema.validate(
            {
                phone,
                friendship_invite_id: inviteId,
            },
            {errors: {escapeHtml: true}}
        );
        if (error !== undefined) {
            setErrors(
                Object.fromEntries(
                    error.details.map((detail) => [detail.path[0], detail.message])
                )
            );
            setPhone("");
            setIsLoading(false);
        } else {
            fetch(new URL("/v1/friendship/invite", KSC_API_URI).href, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(value),
            })
                .then((response) => {
                    const isJson = response.headers
                        .get("content-type")
                        ?.includes("application/json");

                    if (!isJson) {
                        return Promise.reject({
                            message: "Response is not JSON, possible server error",
                            details: {
                                phone: "Server error, try again later",
                            },
                        });
                    }

                    if (response.ok) {
                        return response.json();
                    }

                    switch (response.status) {
                        case 404:
                            return Promise.reject({
                                message: "Friendship invite not found",
                                details: {
                                    phone:
                                        "We were unable to find an invitation matching this link",
                                },
                            });
                        case 406:
                            return Promise.reject({
                                message: "Unacceptable request",
                                details: {
                                    phone: "Invitation link is expired",
                                },
                            });
                        case 409:
                            return Promise.reject({
                                message: "Phone duplicate",
                                details: {
                                    phone: "Phone was already submitted",
                                },
                            });
                        default:
                            return Promise.reject({
                                message: `Unhandled error for status ${response.status}`,
                                details: {
                                    phone: "Unhandled error, try again later",
                                },
                            });
                    }
                })
                .then((data) => {
                    console.log(data);
                    redirectToAppDownload(navigate);
                })
                .catch((error) => {
                    console.error(error);
                    setErrors(
                        error.details || {
                            phone: "Unexpected error, try again later",
                        }
                    );
                })
                .finally(onRequestEnd);
        }
    };

    return (
        <div className="phoneForm">
            <div className="phoneFormInput">
                <PhoneInput
                    className={"PhoneInput " + (errors.phone ? "PhoneInputError" : "")}
                    international
                    countryCallingCodeEditable={true}
                    defaultCountry="SE"
                    value={phone}
                    onChange={onPhoneChange}
                    disabled={isLoading}
                />
            </div>
            {errors.phone && <p className="phone-input-errors">{errors.phone}</p>}

            <p className="phoneFormDescription">
                After clicking Sign up, you will be taken to the store to download the Keyflow app. Please use the same
                phone number while signing in to make sure you connect with your friend.
            </p>

            <button onClick={submitPhone} disabled={!phone || isLoading}>
                Sign up
            </button>
        </div>
    );
};

export default PhoneForm;
