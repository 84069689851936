import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";

import LoadingOverlay from "react-loading-overlay-ts";

import { useMediaQuery } from "react-responsive";

import logo from "./logo.svg";
import desc1 from "./images/desc1.png";
import desc2 from "./images/desc2.png";
import desc3 from "./images/desc3.png";

import PhoneForm from "./PhoneForm";

const Join = () => {
  let params = useParams();
  const inviteId = params.inviteId;

  const [isLoading, setIsLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const findMoreRef = useRef(null);

  const findMoreScroll = () => {
    setShowMore(true);
    setTimeout(() => {
      findMoreRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }, 100);
  };

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

  return (
    <LoadingOverlay active={isLoading} spinner>
      <div
        className={"join " + (isDesktopOrLaptop ? "is-desktop-or-laptop" : "")}
      >
        <header>
          <img src={logo} className="Join-logo" alt="logo" />
          <h1>Your friend invited you to connect on Keyflow</h1>
          <p className="joinDescription">
            Sign up and start partying together!
          </p>
        </header>

        <section>
          <PhoneForm
            inviteId={inviteId}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </section>

        <footer>
          <div className="findMore">
            <span onClick={findMoreScroll}>
              Find out more <i className="arrow down" />
            </span>
          </div>

          <section className={showMore ? "" : "hidden"} ref={findMoreRef}>
            <div>
              <img
                className="description-image"
                src={desc1}
                alt="Description 1"
              />
              <p>Connect with other partygoers</p>
            </div>
            <div>
              <img
                className={
                  "description-image " +
                  (isDesktopOrLaptop ? "non-mobile-image" : "")
                }
                src={desc2}
                alt="Description 2"
              />
              <p>Find events and get access to clubs</p>
            </div>
            <div>
              <img
                className={
                  "description-image " +
                  (isDesktopOrLaptop ? "non-mobile-image" : "")
                }
                src={desc3}
                alt="Description 3"
              />
              <p>Party with your connections</p>
            </div>
          </section>
        </footer>
      </div>
    </LoadingOverlay>
  );
};

export default Join;
